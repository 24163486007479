
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($, _c, undefined) {
	'use strict';

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	var Sage = {

		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages


				var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
				if (iOS === true){
					$('body').addClass('ios');
				}

				if(navigator.userAgent.match(/Trident\/7\./)) { // if IE
					$('body').on("mousewheel", function () {
						// remove default behavior
						event.preventDefault();

						//scroll without smoothing
						var wheelDelta = event.wheelDelta;
						var currentScrollPosition = window.pageYOffset;
						window.scrollTo(0, currentScrollPosition - wheelDelta);
					});
				}

				$(window).on('load', function(){
					$('.nav').addClass('on');
					$('.logo').addClass('on');
					$('.hamburger').addClass('on');
					$('.contact-btn').addClass('on');
				});


				// announcement
				$('.announcement .close').on('click', function (event){
					event.preventDefault();
					$('.announcement').hide();
				});


				// reveal
				if ($('.reveal-up').length > 0){
			        $('.reveal-up').each(function(e){
			            var reveal = $(this);
			            var waypoint_reveal = new Waypoint({
			                element: reveal[0],
			                handler: function(direction) {
			                    if (direction === 'down'){
			                        reveal.addClass('on');
			                    }
			                },
			                offset: '77%'
			            });
			        });
					$(".row").each(function(){
						$(this).children('.reveal-up').each(function(index){
							$(this).css({'transition-delay' : 0.2*(1+index) + 's'});
						});
					});
			    }

				function reveal(){
					if ($(window).width() < 1024){
						$('.reveal').each(function(e){
							var reveal = $(this);
							var waypoint_reveal = new Waypoint({
								element: reveal[0],
								handler: function(direction) {
									if (direction === 'down'){
										reveal.addClass('on');
									}
								},
								offset: '77%'
							});
						});
					}
				}
				reveal();

				if ($('.reveal-left').length > 0){
			        $('.reveal-left').each(function(e){
			            var reveal = $(this);
			            var waypoint_reveal = new Waypoint({
			                element: reveal[0],
			                handler: function(direction) {
			                    if (direction === 'down'){
			                        reveal.addClass('on');
			                    }
			                },
			                offset: '77%'
			            });
			        });
					$(".row").each(function(){
						$(this).children('.reveal-left').each(function(index){
							$(this).css({'transition-delay' : 0.2*(1+index) + 's'});
						});
					});
			    }
				
				// nav
				$('.hamburger').on('click', function (event) {
					event.preventDefault();
					event.stopPropagation();
					if (!$(this).hasClass('is-active')) {
						$(this).addClass('is-active');
						$('.nav').addClass('open');
						$('header').addClass('open');
						$('.page-overlay').fadeIn(400);		
					} else {
						$(this).removeClass('is-active');
						$('.nav').removeClass('open');
						setTimeout(function(){
							$('header').removeClass('open');
						}, 1000);
						$('.page-overlay').hide();
					}
				});

				$('.nav').on('click', function (event){
					event.stopPropagation();
					if ($('.hamburger').hasClass('is-active')) {
						$('.nav').addClass('open');
						$(this).addClass('fixed');
					} else {
						$('.nav').removeClass('open');
						$(this).removeClass('fixed');
						$('.hamburger').removeClass('is-active');
					}
				});

				$('.page-overlay').on('click', function (event){
					$('.nav').removeClass('open').removeClass('fixed');
					setTimeout(function(){
						$('header').removeClass('open');
					}, 1000);
					$('.hamburger').removeClass('is-active');
					$('.page-overlay').hide();
				});

				var dropdownArrowsSet = false;
				function setDropdownArrows(){
					$('ul.main li.menu-item-has-children>a').each(function(){
						var dropdownArrow = document.createElement('span');
						dropdownArrow.innerHTML = '';
						dropdownArrow.classList.add('ico');
						dropdownArrow.setAttribute('aria-hidden', true);
						this.appendChild(dropdownArrow);
					})
					dropdownArrowsSet = true;
				}
				
				$('.column-2')
				
				$('.column-2').hover(
					function() {
						$(this).parents('ul.main').addClass('top-li');
					}, function() {
						$(this).parents('ul.main').removeClass('top-li');
					}
				);

				function mobileNav(){
					if (!dropdownArrowsSet){
						setDropdownArrows();
					}
					if ($(window).width() > 770 && $(window).width() < 1199){
						$('.nav ul.main > li.menu-item-has-children .ico').on('click', function() {
							$('.nav ul.main > li.menu-item-has-children').removeClass('js-hover');
							$(this).parents('li').addClass('js-hover');
							
							return false;
							
						})
					}
					
					/*if ($(window).width() < 769){
						$('.nav ul.main>li>a .ico').on('click', function (event){
							event.preventDefault();
							if ($(this).hasClass('open')){
								$(this).removeClass('open');
								$(this).parent().next().slideUp(400);
							} else  {
								$('.sub-menu').slideUp(400);
								$('.sub-menu').removeClass('open');
								$('.nav ul.main>li>a .ico').removeClass('open');
								$(this).addClass('open');
								$(this).parent().next().slideDown(400);
							}
						});
					}*/
				}
				mobileNav();


				// video
				function fallback(video){
					var img = video.querySelector('img');
					if (img){
						video.parentNode.replaceChild(img, video);
					}
				}


				// banner
				if ($('.secondary-banner').length > 0){
					$(window).on('load', function(){
						$('.loader').hide();
						$('.secondary-banner h1').addClass('on');
						$('.secondary-banner p').addClass('on');
						$('.secondary-banner .btn').addClass('on');
						$('.secondary-banner .blue-line').addClass('on');
						$('.secondary-banner .navy-line').addClass('on');
						$('.secondary-banner .banner').addClass('on');
						$('.secondary-banner .video-cnt').addClass('on');
					});
				}


				// swipers


				if($('#testimonials .swiper-container').length) {
				$('#testimonials .swiper-container').each(function () {
					new Swiper($(this), {
						autoplay: false,
	                    slidesPerView: 1,
	                    loop: false,
	                    speed: 1200,
	                    spaceBetween: 0,
	                    simulateTouch: true,
	                    queueStartCallbacks: true,
	                    queueEndCallbacks: true,
	                    pagination: {
							el: '.swiper-pagination',
							type: 'bullets',
							clickable: true,
							renderBullet: function (index, className) {
								var thumb = $('#slide-'+(index+1)).data('thumb');
								return '<span class="' + className + '" style="background:url('+thumb+') 50% no-repeat;"></span>'; // Modified from Original - remove all instances of /images and /../images
							},
						},
						breakpoints: {

						},
						on: {
							slideChangeTransitionEnd: function () {
								var photo = $('.swiper-slide-active').data('thumb');
								var candid = $('.swiper-slide-active').data('candid');
								$('.quote-photo').css('background', 'url('+photo+') 50% no-repeat');
								$('.testimonials .photo').css('background', 'url('+candid+') 50% no-repeat');
							},
						},
					});
					var photo = $('#testimonials .swiper-slide-active').data('thumb');
					var candid = $('#testimonials .swiper-slide-active').data('candid');
					$('.quote-photo').css('background', 'url('+photo+') 50% no-repeat');
					$('.testimonials .photo').css('background', 'url('+candid+') 50% no-repeat');
				});
				}
				if($('#case-std.swiper-container').lenght) {
					var culture_swiper1 = new Swiper('#case-std.swiper-container', {
							autoplay: true,
							direction: 'vertical',
							slidesPerView: 1,
							pagination: {
									el: '#case-std .swiper-pagination',
									type: 'bullets',
									clickable: true,
							},
							loop: true,
							speed: 1200,
							spaceBetween: 0,
					});
				}				

				var approach_swiper = new Swiper('#approach.swiper-container', {
					autoplay: true,
					slidesPerView: 1,
					loop: true,
					speed: 1200,
					spaceBetween: 0,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					pagination: {
						el: '.swiper-pagination',
						type: 'bullets',
						clickable: true,
					},
					breakpoints: {

					},
				});

				var services_testimonials = new Swiper('#services-testimonials .swiper-container', {
						slidesPerView: 1,
						loop: true,
						speed: 1200,
						spaceBetween: 0,
						simulateTouch: true,
				
						pagination: {
							clickable: true,
							type: 'bullets',
							el: '.swiper-pagination',							
						},
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},
				});

				var culture_swiper = new Swiper('#culture-title.swiper-container', {
					autoplay: true,
					slidesPerView: 1,
					loop: true,
					speed: 1200,
					spaceBetween: 0,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
				});

				setTimeout(function(){
					var culture_swiper1 = new Swiper('#culture1.swiper-container', {
						autoplay: true,
						direction: 'vertical',
						slidesPerView: 1,
						loop: true,
						speed: 1200,
						spaceBetween: 0,
						simulateTouch: false,
						queueStartCallbacks: true,
						queueEndCallbacks: true,
						breakpoints: {

						},
					});
				}, 1000);

				
				var swiper_experts = new Swiper("#experts", {
						navigation: {
						nextEl: "#experts .next",
						prevEl: "#experts .prev",
						},
				});


				setTimeout(function(){
					var culture_swiper2 = new Swiper('#culture2.swiper-container', {
						autoplay: true,
						direction: 'vertical',
						slidesPerView: 1,
						loop: true,
						speed: 1200,
						spaceBetween: 0,
						simulateTouch: false,
						queueStartCallbacks: true,
						queueEndCallbacks: true,
						breakpoints: {

						},
					});
				}, 2000);

				setTimeout(function(){
					var culture_swiper3 = new Swiper('#culture3.swiper-container', {
						autoplay: true,
						direction: 'vertical',
						slidesPerView: 1,
						loop: true,
						speed: 1200,
						spaceBetween: 0,
						simulateTouch: false,
						queueStartCallbacks: true,
						queueEndCallbacks: true,
						breakpoints: {

						},
					});
				}, 3000);


				// form
				$(window).on('load', function(){
					$('select').each(function(){
						var $this = $(this), numberOfOptions = $(this).children('option').length;

						$this.addClass('select-hidden');
						$this.wrap('<div class="select"></div>');
						$this.after('<div class="select-styled"></div>');

						var $styledSelect = $this.next('div.select-styled');
						$styledSelect.text($this.children('option').eq(0).text());

						var $list = $('<ul />', {
							'class': 'select-options'
						}).insertAfter($styledSelect);

						for (var i = 0; i < numberOfOptions; i++) {
							$('<li />', {
								text: $this.children('option').eq(i).text(),
								rel: $this.children('option').eq(i).val()
							}).appendTo($list);
						}

						var $listItems = $list.children('li');

						$styledSelect.click(function(e) {
							e.stopPropagation();
							$('.select-item label').addClass('on');
							$('div.select-styled.active').not(this).each(function(){
								$(this).removeClass('active').next('ul.select-options').hide();
							});
							$(this).toggleClass('active').next('ul.select-options').toggle();
						});

						$listItems.click(function(e) {
							e.stopPropagation();
							$styledSelect.text($(this).text()).removeClass('active');
							$this.val($(this).attr('rel'));
							$list.hide();
						});

						$(document).click(function() {
							$styledSelect.removeClass('active');
							$list.hide();
							if ($.trim($('.select-styled').text()) === ""){
								$('.select-item label').removeClass('on');
							} else {
								$('.select-item label').addClass('on');
							}
						});

					});
				});

				$('input[type="submit"]').wrap('<div class="submit-wrap"></div>');

				$('form input').on('focus', function(){
					$(this).parent().prev().addClass('on');
				});

				$('form input').on('blur', function(){
					if ($(this).val() != '') {

					} else {
						$(this).parent().prev().removeClass('on');
					}
				});

				$(window).on('load', function(){
					$('.gform_footer').append('<span class="ico"></span>');
					$('.ginput_container_select').parent().addClass('select-item');
					var input = $('form input');
					console.log(input);
					if ($(input).val() != '') {
						$(input).parent().prev().addClass('on');
					} else {
						$(input).parent().prev().removeClass('on');
					}
				});
			
				
				
				$('.work-testimonials .work-services').each(function(e){
					var reveal = $(this);
					var waypoint_reveal = new Waypoint({
						element: reveal[0],
						handler: function(direction) {
							if (direction === 'down'){
								reveal.addClass('active');
							}
						},
						offset: '100%'
					});
				});
				
				
				$('.work-testimonials .testimonials').each(function(e){
					var reveal = $(this);
					var waypoint_reveal = new Waypoint({
						element: reveal[0],
						handler: function(direction) {
							if (direction === 'down'){
								reveal.addClass('active');
							}
						},
						offset: '50%'
					});
				});
				
				
				$('.page-template-template-service-overview-page .insights-section').each(function(e){
					var reveal = $(this);
					var waypoint_reveal = new Waypoint({
						element: reveal[0],
						handler: function(direction) {
							if (direction === 'down'){
								reveal.addClass('active');
							}
						},
						offset: '50%'
					});
				});	

				$('.page-template-template-service-overview-page .team-services').each(function(e){
					var reveal = $(this);
					var waypoint_reveal = new Waypoint({
						element: reveal[0],
						handler: function(direction) {
							if (direction === 'down'){
								reveal.addClass('active');
							}
						},
						offset: '50%'
					});
				});					

				$('.page-template-template-service-overview-page .contact-section').each(function(e){
					var reveal = $(this);
					var waypoint_reveal = new Waypoint({
						element: reveal[0],
						handler: function(direction) {
							if (direction === 'down'){
								reveal.addClass('active');
							}
						},
						offset: '50%'
					});
				});	

				// NEW TEMPLATE 
				/*$('.service-template-templates .js-tabs li a').click(function(){					
					var elem = $(this).attr('href');
					
					$('.service-template-templates .js-tabs li').removeClass('current');
					$('.service-template-templates .container-tab').hide();
					$(this).parents('li').addClass('current');
					$(elem).show();
					
					return false;
				});*/		
				
				$('.service-template-templates .js-tabs li a').click(function(){					
					var elem = $(this).attr('href');					
					
					$('html, body').animate({
							scrollTop: parseInt($(elem).offset().top)
					}, 1000);
					
					return false;
				});
				
				if($('.service-template-templates #expert-slider .swiper-container').length) {
					var experts_swiper = new Swiper('.service-template-templates #expert-slider .swiper-container', {
						autoplay: false,
						slidesPerView: 1,
						slidesPerGroup: 1,
						loop: true,
						speed: 1200,
						spaceBetween: 0,
						simulateTouch: false,
						queueStartCallbacks: true,
						queueEndCallbacks: true,
						/*navigation: {
							prevEl: '.service-template-templates .js-arrows .prev',
							nextEl: '.service-template-templates .js-arrows .next',
						},*/
						pagination: {
							el: '.swiper-pagination',
							type: 'bullets',
							clickable: true,
						},					
					});

					$('.service-template-templates .js-arrows .prev').on('click', function (event){
						event.preventDefault();
						experts_swiper.slidePrev();
					});

					$('.service-template-templates .js-arrows .next').on('click', function (event){
						event.preventDefault();
						experts_swiper.slideNext();
					});
				}
				
				if($('.service-template-templates #helps .swiper-container').length) {
					var helps_swiper = new Swiper('.service-template-templates #helps .swiper-container', {
						autoplay: false,
						slidesPerView: 2.25,
						//slidesPerGroup: 1,
						loop: false,
						speed: 1200,
						spaceBetween: 25,
						simulateTouch: true,
						queueStartCallbacks: true,
						queueEndCallbacks: true,
						breakpoints: {						
							767: {
								slidesPerView: 1,							
							}
						},					
						pagination: {
							el: '.swiper-pagination',
							type: 'bullets',
							clickable: true,
						},					
					});

					$('.service-template-templates #helps .arrows .prev').on('click', function (event){
						event.preventDefault();
						helps_swiper.slidePrev();
					});

					$('.service-template-templates #helps .arrows .next').on('click', function (event){
						event.preventDefault();
						helps_swiper.slideNext();
					});
				}
				
				
				if($('.service-template-templates #articles-1.swiper-container').length) {
				var articles_swiper1 = new Swiper('.service-template-templates #articles-1.swiper-container', {
					autoplay: false,
					slidesPerView: 3,
					slidesPerGroup: 1,
					loop: false,
					speed: 1200,
					spaceBetween: 20,
					simulateTouch: true,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					pagination: {
						el: ".article-pagination",
						type: "fraction",
					},
					breakpoints: {
						1023: {
							slidesPerView: 2,
							//slidesPerGroup: 2,
						},
						767: {
							slidesPerView: 1,
							//slidesPerGroup: 1,
						}
					},				
				});

				$('.service-template-templates #articles-1 .arrows .prev').on('click', function (event){
					event.preventDefault();
					articles_swiper1.slidePrev();
				});

				$('.service-template-templates #articles-1 .arrows .next').on('click', function (event){
					event.preventDefault();
					articles_swiper1.slideNext();
				});
				}
	

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Home page
		'page_template_template_home': {
			init: function() {
				// JavaScript to be fired on all pages

				// on load
				$(window).on('load', function(){
					$('.loader').hide();
					var page_width = $(window).width();
					$('.hero .logo').addClass('on');
					$('.hero h1').addClass('on');
					$('.hero .scrollto').addClass('on');
					$('.hero .blue-line').addClass('on');
					$('.hero .navy-line').addClass('on');
					$('.hero .banner').addClass('on');
				});

				var tooSmall = false;
				var controller = null;
				var maxWidth = 1024; // sizes above maxWidth will turn on scroll magic

				if( $(window).width() < maxWidth ) {
					tooSmall = true;
				}

				var controller = new ScrollMagic.Controller();

				function scrollMagic(){

					// win width
					var win_width = $(window).width() / 2;

					// count panels
					var slideContainer = $('#slideContainer .panel').length * 100;
					$('#slideContainer').css('width', slideContainer+'vw');
					var slideContainer2 = $('#slideContainer2 .panel').length * 100;
					$('#slideContainer2').css('width', slideContainer2+'vw');

					var duration1 = $('#slideContainer').width();
					var duration2 = $('#slideContainer2').width();

					// count case studies
					var scrollContainer = $('#scrollContainer .panel').length * 100;
					$('#scrollContainer').css('height', scrollContainer+'vh');

					// init controller
					var controller = new ScrollMagic.Controller();

					// define movement of panels
					var section_width1 = -Math.abs($('#slideContainer').width() - $(window).width());
					var wipeAnimationHozizontal = new TimelineMax().to("#slideContainer", 1,   {x: section_width1, rotation:0.01});
					var section_width2 = -Math.abs($('#slideContainer2').width() - $(window).width());
					var wipeAnimationHozizontal2 = new TimelineMax().to("#slideContainer2", 1,   {x: section_width2, rotation:0.01});

					// create scene to pin and link animation
					var scene1 = new ScrollMagic.Scene({
						triggerElement: "#pinContainer1",
						triggerHook: 0,
						duration: duration1
					})
					.setPin("#pinContainer1")
					.setTween(wipeAnimationHozizontal);

					var scene2 = new ScrollMagic.Scene({
						triggerElement: "#pinContainer21",
						triggerHook: 0,
						duration: duration2
					})
					.setPin("#pinContainer21")
					.setTween(wipeAnimationHozizontal2);

					// panel 1 animation
					var hero_tl = new TimelineMax()
					.add([
						TweenMax.to( '.hero .overlay', .5, { opacity: 0 }),
						TweenMax.to( '.hero .banner', .5, { opacity: 0 }),
						TweenMax.to( '.hero .cnt', 1, { left: win_width, opacity: 0 })
					]);

					var hero_fade = new ScrollMagic.Scene({duration: win_width}).setTween(hero_tl);

					// add scenes
					controller.addScene([
						scene1,
						scene2,
						hero_fade,
					]);

					var v = document.getElementsByTagName("video")[0];
					v.play();

				}

				if( !tooSmall ) {
					//scrollMagic();
				}

				$(window).resize( function() {
					var wWidth = $(window).width();
					if( wWidth < maxWidth ) {
						if( controller !== null && controller !== undefined ) {

							controller = controller.destroy(true);

							$('#pinContainer1').removeAttr('style');
							$('#pinContainer21').removeAttr('style');
							$('.scrollmagic-pin-spacer').removeAttr('style');
							$('#slideContainer').removeAttr('style');
							$('#slideContainer2').removeAttr('style');
						}
					} else if( wWidth >= maxWidth ) {
						if( controller === null || controller === undefined ) {

							//scrollMagic();
						}
					}
				});

				// reveal animation
				(function ($) {

					$.fn.isOnScreen = function(test){

					  var height = this.outerHeight();
					  var width = this.outerWidth();

					  if(!width || !height){
						return false;
					  }

					  var win = $(window);

					  var viewport = {
						top : win.scrollTop(),
						left : win.scrollLeft()
					  };
					  viewport.right = viewport.left + win.width();
					  viewport.bottom = viewport.top + win.height();

					  var bounds = this.offset();
					  bounds.right = bounds.left + width;
					  bounds.bottom = bounds.top + height;

					  var deltas = {
						top : viewport.bottom - bounds.top,
						left: viewport.right - bounds.left,
						bottom: bounds.bottom - viewport.top,
						right: bounds.right - viewport.left
					  };

					  if(typeof test == 'function') {
						return test.call(this, deltas);
					  }

					  return deltas.top > 0
						&& deltas.left > 0
						&& deltas.right > 0
						&& deltas.bottom > 0;
					};

				})(jQuery);

				$(".panel").each(function(){
					$(this).find('.reveal').each(function(index){
						$(this).css({'transition-delay' : 0.1*(1+index) + 's'});
					});
				});

				var left_offset = 100;

				var options = {
					  useEasing: true,
					  useGrouping: true,
					  separator: '',
					  decimal: '',
				};

				// on scroll
				$(window).on('scroll', function(){

					$('#about .reveal').each(function(){
						if ($(this).isOnScreen(function(deltas){
								return deltas.left >= left_offset
								})
							){
							$(this).addClass('on');
						} else {
							$(this).removeClass('on');
							$('.value').removeClass('ran');
						}
						$('.value').each(function(){
							if ($(this).hasClass('on')){
								if (!$(this).hasClass('ran')){
									$(this).addClass('ran');
									$(this).addClass('show');
								}
							}
						});
					});

					if ($(window).width() > 1023){
						$('#stats .reveal').each(function(){
							if ($(this).isOnScreen(function(deltas){
									return deltas.left >= left_offset
									})
								){
								$(this).addClass('on');
							} else {
								$(this).removeClass('on');
							}
							if ($('.map-cnt h2').isOnScreen(function(deltas){
								return deltas.left >= $('.stats').width() / 2
								})
							){
								$('.map-cnt').addClass('active');
							} else {
								$('.map-cnt').removeClass('active');
							}
						});
					} else {
						$('.map-cnt').each(function(e){
							var reveal = $(this);
							var waypoint_reveal = new Waypoint({
								element: reveal[0],
								handler: function(direction) {
									if (direction === 'down'){
										reveal.addClass('active');
									}
								},
								offset: '50%'
							});
						});
					}

					if ($('#num-0').length > 0){
						var num0 = $('#num-0').text();
						if ($('#stat-0').hasClass('on')){
							if (!$('#num-0').hasClass('ran')){
								$('#num-0').addClass('ran');
								var stat0 = new CountUp('num-0', 0, num0, 0, 2.5, options);
								stat0.start();
							}
						}
					}
					if ($('#num-1').length > 0){
						var num1 = $('#num-1').text();
						if ($('#stat-1').hasClass('on')){
							if (!$('#num-1').hasClass('ran')){
								$('#num-1').addClass('ran');
								var stat1 = new CountUp('num-1', 0, num1, 0, 2.5, options);
								stat1.start();
							}
						}
					}
					if ($('#num-2').length > 0){
						var num2 = $('#num-2').text();
						if ($('#stat-2').hasClass('on')){
							if (!$('#num-2').hasClass('ran')){
								$('#num-2').addClass('ran');
								var stat2 = new CountUp('num-2', 0, num2, 0, 2.5, options);
								stat2.start();
							}
						}
					}

					$('#services .reveal').each(function(){
						if ($(this).isOnScreen(function(deltas){
								return deltas.left >= left_offset
								})
							){
							$(this).addClass('on');
						} else {
							$(this).removeClass('on');
						}
						$('.services .service-links').each(function(){
							if ($(this).hasClass('on')){
								if (!$(this).children('li').children('a').hasClass('ran')){
									$(this).children('li').children('a').addClass('ran');
									$(this).children('li').children('a').addClass('show');
								}
							}
						});
					});

					var case_studies = $('.case-study').length;

					$('.case-study').each(function(i){
						if ($('#case-study-'+i).isOnScreen(function(deltas){
							return deltas.top >= $(window).height();
							})
						){
							if ((i+1) <= case_studies){
								$('#case-study-'+i).addClass('fixed');
							}
						} else {
							$('#case-study-'+i).removeClass('fixed');
						}
					});

					$('#testimonials .reveal').each(function(){
						if ($(this).isOnScreen(function(deltas){
								return deltas.left >= left_offset
								})
							){
							$(this).addClass('on');
						} else {
							$(this).removeClass('on');
						}
					});

					$('#closing .reveal').each(function(){
						if ($(this).isOnScreen(function(deltas){
								return deltas.left >= left_offset
								})
							){
							$(this).addClass('on');
							$('.closing .blue-line').addClass('on');
							$('.closing .navy-line').addClass('on');
						} else {
							$(this).removeClass('on');
							$('.closing .blue-line').removeClass('on');
							$('.closing .navy-line').removeClass('on');
						}
					});

				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// About page
		'page_template_template_about': {
			init: function() {
				// JavaScript to be fired on all pages

                if ($(window).width() > 1024) {

					// set up positioning
					var element_number = $('.timeline-post').length;
					var history_width = $('.timeline-post').width();
					var element_margin = element_number * 20;
					var place_left = 0;

					$('.timeline-post').each(function(i){

						var isFirstIteration = i % 3 === 0;
						var isSecondIteration = i % 3 == 1;
						var isThirdIteration = i % 3 === 2;

						if (isFirstIteration){
							$(this).css('top', '65px');
							$(this).css('left', place_left+'px');
							place_left = place_left + history_width;
						}
						if (isSecondIteration){
							$(this).css('top', '0');
							$(this).css('left', place_left+'px');
							$(this).children('.caption').addClass('top');
						}
						if (isThirdIteration){
							$(this).css('top', '225px');
							$(this).css('left', place_left+'px');
							$(this).children('.caption').addClass('bottom');
							place_left = place_left + history_width;
						}

					});

					// set up container
					var last_item = $('.timeline-post:last-child').position().left;
					$('.landing-inner-content').css('width', last_item + history_width + element_margin +'px');

					// set up timeline
					var tl = new TimelineLite({paused: true});
					var progress = 0;

					function timeline(){
						var element = $('.landing-inner-content');
						var history = $('.timeline-post:last-child').position().left - $(window).width();
						var moveX = -Math.abs(history + history_width + element_margin);
						tl.to($(element), 5, {ease: Power0.easeNone, x: moveX, rotation: 0.01});
						tl.progress(progress);
						element.animation = tl;
						gsap.defaultEase = Linear.easeNone;

						// set up hover triggers
						var right_hover = $('.landing-wrapper').width() / 4;
						var left_hover = $('.landing-wrapper').width() - right_hover;

						function onTick(){
							if (tl.isActive() === false){
								element.animation.pause();
							}
						}

						$('.landing-wrapper').on("mousemove", function (e) {
							if (e.clientX > right_hover) {
								element.animation.play();
							}
							if (e.clientX < left_hover) {
								element.animation.reverse();
							}
							if (e.clientX < left_hover && e.clientX > right_hover) {
								element.animation.pause();
							}
						});

						gsap.ticker.addEventListener("tick", onTick);

					}
					timeline();

					$(window).on('resize', function(){
						timeline();
					});

                }

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Services page
		'page_template_template_services': {
			init: function() {
				// JavaScript to be fired on all pages

				$('.services-toggle li a').on('click', function (event){
					event.preventDefault();
					var service = $(this).attr('href');
					$('.service').hide();
					$('.service .col-xs-6').removeClass('on');
					$('.services-toggle li').removeClass('active');
					$(this).parent().addClass('active');
					$(service).fadeIn(400);
					$(service+' .col-xs-6:first-child').addClass('on');
					$(service+' .col-xs-6:last-child').addClass('on');
				});

				function mobileToggle(){
					if ($(window).width() < 1023){
						// var toggle = $('.services-toggle li.active').text();
						// $('.toggle-dd .text').text(toggle);

						$('.toggle-dd').on('click', function (event){
							event.preventDefault();
							if ($(this).hasClass('open')){
								$(this).removeClass('open');
								$('.services-toggle').slideUp(400);
							} else {
								$(this).addClass('open');
								$('.services-toggle').slideDown(400);
							}
						});

						$('.services-toggle li a').on('click', function (event){
							event.preventDefault();
							var toggle = $(this).text();
							$('.toggle-dd .text').text(toggle);
							$('.toggle-dd').removeClass('open');
							$('.services-toggle').slideUp(400);
						});
					}
				}
				mobileToggle();

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},

		'page_template_template_service_overview_page': {
			init: function() {
				// JavaScript to be fired on all pages

				var articles_swiper = new Swiper('#articles.swiper-container', {
					autoplay: false,
					slidesPerView: 3,
					slidesPerGroup: 3,
					loop: true,
					speed: 1200,
					spaceBetween: 20,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					/*pagination: {
						el: ".article-pagination",
						type: "fraction",
					},*/
					breakpoints: {
						1023: {
							slidesPerView: 2,
							slidesPerGroup: 2,
						},
						767: {
							slidesPerView: 1,
							slidesPerGroup: 1,
						}
					},
				});

				$('#articles.swiper-container .arrows .prev').on('click', function (event){
					event.preventDefault();
					articles_swiper.slidePrev();
				});

				$('#articles.swiper-container .arrows .next').on('click', function (event){
					event.preventDefault();
					articles_swiper.slideNext();
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},

		// Single Services page
		'page_template_template_single_service': {
			init: function() {
				// JavaScript to be fired on all pages

				var testimonial_swiper = new Swiper('#testimonial.swiper-container', {
					autoplay: false,
					slidesPerView: 1,
					loop: true,
					speed: 1200,
					spaceBetween: 50,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					navigation: {
						nextEl: '#testimonial .next',
						prevEl: '#testimonial .prev',
					},
					breakpoints: {

					},
				});

				var articles_swiper = new Swiper('#articles.swiper-container', {
					autoplay: false,
					slidesPerView: 3,
					slidesPerGroup: 3,
					loop: true,
					speed: 1200,
					spaceBetween: 20,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					breakpoints: {
						1023: {
							slidesPerView: 2,
							slidesPerGroup: 2,
						},
						767: {
							slidesPerView: 1,
							slidesPerGroup: 1,
						}
					},
				});

				$('#articles.swiper-container .arrows .prev').on('click', function (event){
					event.preventDefault();
					articles_swiper.slidePrev();
				});

				$('#articles.swiper-container .arrows .next').on('click', function (event){
					event.preventDefault();
					articles_swiper.slideNext();
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Single Services page
		'page_template_template_single_service2': {
			init: function() {
				// JavaScript to be fired on all pages

				var testimonial_swiper = new Swiper('#testimonial.swiper-container', {
					autoplay: false,
					slidesPerView: 1,
					loop: true,
					speed: 1200,
					spaceBetween: 50,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					navigation: {
						nextEl: '#testimonial .next',
						prevEl: '#testimonial .prev',
					},
					breakpoints: {

					},
				});

				var articles_swiper = new Swiper('#articles.swiper-container', {
					autoplay: false,
					slidesPerView: 3,
					slidesPerGroup: 3,
					loop: true,
					speed: 1200,
					spaceBetween: 20,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					breakpoints: {
						1023: {
							slidesPerView: 2,
							slidesPerGroup: 2,
						},
						767: {
							slidesPerView: 1,
							slidesPerGroup: 1,
						}
					},
				});

				$('#articles.swiper-container .arrows .prev').on('click', function (event){
					event.preventDefault();
					articles_swiper.slidePrev();
				});

				$('#articles.swiper-container .arrows .next').on('click', function (event){
					event.preventDefault();
					articles_swiper.slideNext();
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Blog page
		'page_template_template_blog': {
			init: function() {
				// JavaScript to be fired on all pages

				$(window).on('load', function(){
					$('nav.filter-bar form').append('<a href="#" class="close"></a>');
					$('nav.filter-bar form').attr('id', 'search');
					$('#search .close').on('click', function (event){
						event.preventDefault();
						$('.filter-bar ul').show();
						$('.filter-bar form').removeClass('open');
						var start_left = $('.filter-bar ul li.active').position().left;
						var start_width = $('.filter-bar ul li.active').width();
						$('.filter-bar .underline').css({'left': start_left+'px', 'width': start_width+'px'});
					});
				});

				// stick nav
				if ($('.filter-bar').length > 0){
					$('.filter-bar').each(function(e){
						var aside = $(this);
						var waypointAside = new Waypoint({
							element: aside[0],
							handler: function(direction) {
								if (direction === 'down'){
									aside.addClass('fixed');
								}
								if (direction === 'up'){
									aside.removeClass('fixed');
								}
							},
							offset: '0%'
						});
					});
				}

				$('.search-open').on('click', function (event){
					event.preventDefault();
					$('.filter-bar ul').hide();
					$('.filter-bar form').addClass('open');
					$('.filter-bar form .search-field').focus();
				});

				function mobileFilter(){
					if ($(window).width() < 1023){
						var toggle = $('.filter-bar li.active').text();
						$('.filter-dd .text').text(toggle);

						$('.filter-dd').on('click', function (event){
							event.preventDefault();
							if ($(this).hasClass('open')){
								$(this).removeClass('open');
								$('.filter-bar ul').slideUp(400);
							} else {
								$(this).addClass('open');
								$('.filter-bar ul').slideDown(400);
							}
						});

						$('.filter-bar li a').on('click', function (event){
							event.preventDefault();
							var toggle = $(this).text();
							$('.filter-dd .text').text(toggle);
							$('.filter-dd').removeClass('open');
							$('.filter-bar ul').slideUp(400);
						});
					}
				}
				mobileFilter();

				$(window).on('resize', function(){
					mobileFilter();
				});

				// Set to active list item
				$(window).on('load', function(){
					$('.filter-bar').append('<div class="underline"></div>');
					var start_left = $('.filter-bar ul li.active').position().left;
					var start_width = $('.filter-bar ul li.active').width();
					$('.filter-bar .underline').css({'left': start_left+'px', 'width': start_width+'px'});
				});

				// Follow and return on hover
				$('.filter-bar ul li').on('mouseover', function(){
					var this_left = $(this).position().left;
					var this_width = $(this).width();
					$('.filter-bar .underline').css({'left': this_left+'px', 'width': this_width+'px'});
				}).on('mouseleave', function(){
					var active_left = $('.filter-bar li.active').position().left;
					var active_width = $('.filter-bar li.active').width();
					$('.filter-bar .underline').css({'left': active_left+'px', 'width': active_width+'px'});
				});

				$(window).on('resize', function(){
					var this_left = $('.filter-bar li.active').position().left;
					var this_width = $('.filter-bar li.active').width();
					$('.filter-bar .underline').css({'left': this_left+'px', 'width': this_width+'px'});
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Single page
		'post_template_default': { // changed from 'page_template_template_single' -CB
			init: function() {
				// JavaScript to be fired on all pages

				var articles_swiper = new Swiper('#articles.swiper-container', {
					autoplay: false,
					slidesPerView: 3,
					slidesPerGroup: 3,
					loop: true,
					speed: 1200,
					spaceBetween: 20,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					breakpoints: {
						1023: {
							slidesPerView: 2,
							slidesPerGroup: 2,
						},
						767: {
							slidesPerView: 1,
							slidesPerGroup: 1,
						}
					},
				});

				$('#articles.swiper-container .arrows .prev').on('click', function (event){
					event.preventDefault();
					articles_swiper.slidePrev();
				});

				$('#articles.swiper-container .arrows .next').on('click', function (event){
					event.preventDefault();
					articles_swiper.slideNext();
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// People page
		'page_template_template_people': {
			init: function() {
				// JavaScript to be fired on all pages

				// draggable
				(function(){

					if ($(window).width() > 768){

						var draggable_width = ($('#draggable .team').length * $('#draggable .team').width()) + ($('#draggable .team').length * 30);
						$('#draggable').css('width', draggable_width - 30);

						//set up width and height of draggable container according to view container and draggable size
						var dragContainerWidth = $("#viewContainer").innerWidth() + ($('#draggable').outerWidth() - $("#viewContainer").innerWidth()) * 2;
						var dragContainerHeight = $("#viewContainer").innerHeight() + ($('#draggable').outerHeight() - $("#viewContainer").innerHeight()) * 2;

						$("#draggableContainer").css("width",dragContainerWidth);
						$("#draggableContainer").css("height",dragContainerHeight);
						var draggableLeftPos = dragContainerWidth / 2;
						var draggableTopPos = dragContainerHeight / 2;

						//set up position of draggable container according to view container and draggable size
						var dragContainerOffsetLeft = $("#viewContainer").offset().left + $("#viewContainer").outerWidth()/2 + $("#viewContainer").innerWidth()/2 - $('#draggable').outerWidth();
						var dragContainerOffsetTop = $("#viewContainer").offset().top + $("#viewContainer").outerHeight()/2 + $("#viewContainer").innerHeight()/2 - $('#draggable').outerHeight();

						$("#draggableContainer").offset({left:dragContainerOffsetLeft,top:dragContainerOffsetTop});

						//draggable
						var parentPos = $('#draggable').offset();
						var centerPos = $('#centerPos').offset();

						//set starting positions
						$('#draggable').css({'top': draggableTopPos, 'left': draggableLeftPos});

						//activate draggable
						$('#draggable').draggable({
							containment:"#draggableContainer",
							scroll: false,
							drag: function(event, ui){
								$('.team a').hide();
							},
							stop: function(event, ui){
								$('.team a').show();
							},
						});

					}

				})();

				// Commented out as scope doc has changed
				// $('#draggable .team a').on('click', function (event){
				// 	event.preventDefault();
				// 	var bio = $(this).attr('href');
				// 	$(bio).addClass('open');
				// 	$('.page-overlay').addClass('active');
				// });

				// $('.bio-popout .close').on('click', function (event){
				// 	event.preventDefault();
				// 	$('.bio-popout').removeClass('open');
				// 	$('.page-overlay').hide();
				// });

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Single Team page
		'page_template_template_single_team': {
			init: function() {
				// JavaScript to be fired on all pages

				$('.toggle a').on('click', function (event){
					event.preventDefault();
					var tab = $(this).attr('href');
					if (!$(this).parent().hasClass('active')){
						$('.tab').hide();
						$(tab).fadeIn(400);
						$('.toggle li').removeClass('active');
						$(this).parent().addClass('active');
					}
				});

				function fixedPhoto(){
					if ($(window).width() > 1023){
						var scrollPos = 0;
						var image_top = $('.tab').offset().top;
						var tab_cnt_bottom = $('.tab').height() + image_top;
						var tab_bottom = (tab_cnt_bottom - $(window).height()) + $('.tab img').height() / 2;

						window.addEventListener('scroll', function(){
							var scroll_top = $(window).scrollTop();
							if (scroll_top >= image_top){
								$('.tab img').css({'position' : 'fixed', 'top' : '20px'});
							}
							if (scroll_top >= tab_bottom){
								$('.tab img').css({'position' : 'absolute', 'top' : tab_bottom+'px'});
							}
							if ((document.body.getBoundingClientRect()).top > scrollPos){
								if (scroll_top < image_top){
									$('.tab img').css({'position' : 'static', 'top' : 'auto'});
								}
							}
							scrollPos = (document.body.getBoundingClientRect()).top;
						});
					}
				}
				fixedPhoto();

				function photoLeft(){
					if ($(window).width() > 1023){
						var photo_left = $('.tab img').offset().left;
						$('.tab img').css('left', photo_left+'px');
					}
				}
				photoLeft();

				$(window).on('resize', function(){
					photoLeft();
					fixedPhoto();
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Single Location page
		'page_template_template_single_location': {
			init: function() {
				// JavaScript to be fired on all pages

				// draggable
				(function(){

					if ($(window).width() > 768){

						var draggable_width = ($('#draggable .team').length * $('#draggable .team').width()) + ($('#draggable .team').length * 30);
						$('#draggable').css('width', draggable_width - 30);

						//set up width and height of draggable container according to view container and draggable size
						var dragContainerWidth = $("#viewContainer").innerWidth() + ($('#draggable').outerWidth() - $("#viewContainer").innerWidth()) * 2;
						var dragContainerHeight = $("#viewContainer").innerHeight() + ($('#draggable').outerHeight() - $("#viewContainer").innerHeight()) * 2;

						$("#draggableContainer").css("width",dragContainerWidth);
						$("#draggableContainer").css("height",dragContainerHeight);
						var draggableLeftPos = dragContainerWidth / 2;
						var draggableTopPos = dragContainerHeight / 2;

						//set up position of draggable container according to view container and draggable size
						var dragContainerOffsetLeft = $("#viewContainer").offset().left + $("#viewContainer").outerWidth()/2 + $("#viewContainer").innerWidth()/2 - $('#draggable').outerWidth();
						var dragContainerOffsetTop = $("#viewContainer").offset().top + $("#viewContainer").outerHeight()/2 + $("#viewContainer").innerHeight()/2 - $('#draggable').outerHeight();

						$("#draggableContainer").offset({left:dragContainerOffsetLeft,top:dragContainerOffsetTop});

						//draggable
						var parentPos = $('#draggable').offset();
						var centerPos = $('#centerPos').offset();

						//set starting positions
						$('#draggable').css({'top': draggableTopPos, 'left': draggableLeftPos});

						//activate draggable
						$('#draggable').draggable({
							containment:"#draggableContainer",
							scroll: false,
							drag: function(event, ui){
								$('#location-team a').hide();
							},
							stop: function(event, ui){
								$('#location-team a').show();
							},
						});

					}

				})();

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		
		// 
		'page_template_template_service_details_page': {
			init: function() {
				// JavaScript to be fired on all pages
			
			
			$('.page-template-template-service-details-page .js-tabs li a').click(function(){					
					var elem = $(this).attr('href');					
					
					$('html, body').animate({
							scrollTop: parseInt($(elem).offset().top)
					}, 1000);
					
					return false;
				});
				
				if($('.page-template-template-service-details-page #expert-slider .swiper-container').length) {
					var experts_swiper = new Swiper('.page-template-template-service-details-page #expert-slider .swiper-container', {
						autoplay: false,
						slidesPerView: 1,
						slidesPerGroup: 1,
						loop: true,
						speed: 1200,
						spaceBetween: 0,
						simulateTouch: false,
						queueStartCallbacks: true,
						queueEndCallbacks: true,					
						pagination: {
							el: '.swiper-pagination',
							type: 'bullets',
							clickable: true,
						},					
					});

					$('.page-template-template-service-details-page .js-arrows .prev').on('click', function (event){
						event.preventDefault();
						experts_swiper.slidePrev();
					});

					$('.page-template-template-service-details-page .js-arrows .next').on('click', function (event){
						event.preventDefault();
						experts_swiper.slideNext();
					});
				}
				
				if($('.page-template-template-service-details-page #helps .swiper-container').length) {
					var helps_swiper = new Swiper('.page-template-template-service-details-page #helps .swiper-container', {
						autoplay: false,
						slidesPerView: 2.25,
						//slidesPerGroup: 1,
						loop: false,
						speed: 1200,
						spaceBetween: 25,
						simulateTouch: true,
						queueStartCallbacks: true,
						queueEndCallbacks: true,
						breakpoints: {						
							767: {
								slidesPerView: 1,							
							}
						},					
						pagination: {
							el: '.swiper-pagination',
							type: 'bullets',
							clickable: true,
						},					
					});

					$('.page-template-template-service-details-page #helps .arrows .prev').on('click', function (event){
						event.preventDefault();
						helps_swiper.slidePrev();
					});

					$('.page-template-template-service-details-page #helps .arrows .next').on('click', function (event){
						event.preventDefault();
						helps_swiper.slideNext();
					});
				}
				
				
				if($('.page-template-template-service-details-page #articles-1.swiper-container').length) {
					var articles_swiper1 = new Swiper('.page-template-template-service-details-page #articles-1.swiper-container', {
						autoplay: false,
						slidesPerView: 3,
						slidesPerGroup: 1,
						loop: false,
						speed: 1200,
						spaceBetween: 20,
						simulateTouch: true,
						queueStartCallbacks: true,
						queueEndCallbacks: true,
						pagination: {
							el: ".article-pagination",
							type: "fraction",
						},
						breakpoints: {
							1023: {
								slidesPerView: 2,
								//slidesPerGroup: 2,
							},
							767: {
								slidesPerView: 1,
								//slidesPerGroup: 1,
							}
						},				
					});

					$('.page-template-template-service-details-page #articles-1 .arrows .prev').on('click', function (event){
						event.preventDefault();
						articles_swiper1.slidePrev();
					});

					$('.page-template-template-service-details-page #articles-1 .arrows .next').on('click', function (event){
						event.preventDefault();
						articles_swiper1.slideNext();
					});
				}

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},

	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
